/**
 * External Dependencies
 */
import 'jquery';

console.log('Parent: I am coming from the Bivi Base Parent theme!');
const parentTheme = 'parent';
$(() => {
  /*////////////////////////
	//Mobile Menu Toggle
	////////////////////////*/
	$(function() {
		$('[data-drawer-open-button]').on('click', function() {
      console.log('click');
			$(this).attr('tabindex', '-1');
			$('.mobile-navigation-drawer').removeAttr('inert').removeAttr('aria-hidden').attr('open', '').attr('tabindex', '-1');
			$('body').addClass('mobile-is-open');
		});
		$('[data-drawer-close-button]').on('click', function() {
			$('body').removeClass('mobile-is-open');
			$('.mobile-navigation-drawer').removeAttr('open').attr('aria-hidden', 'true').attr('inert', '');
		});
	});
	// quick rms city mock
	// $(function() {
	// 	$('[data-city-trigger]').on('click', function(e) {
	// 		$('[data-city-target='+ e.currentTarget.dataset.cityTrigger +']')
	// 			.addClass('flex')
	// 			.removeClass('hidden')
	// 			.removeClass('pointer-events-none');
	// 	});
	// 	$('[data-city-close]').on('click', function() {
	// 		$('[data-city-target]')
	// 			.removeClass('flex')
	// 			.addClass('hidden')
	// 			.addClass('pointer-events-none');
	// 	});
	// });



	//////////////////////////
	//GOOGLE MAPS
	//////////////////////////

	(function($) {

    function render_map( $el ) {

      // var
      var $markers = $el.find('.marker');

      // vars
      var args = {
        zoom: 16,
        center: new google.maps.LatLng(0, 0),
        scrollwheel: false,
        mapTypeId: 'terrain',
        disableDefaultUI: true,
      };

      // create map
      var map = new google.maps.Map( $el[0], args);
      // map.setTilt(45);

      // add a markers reference
      map.markers = [];

      // add markers
      $markers.each(function(){

          add_marker( $(this), map );

      });

      // center map
      center_map( map );

    }

    function add_marker( $marker, map ) {

      // var
      var latlng = new google.maps.LatLng( $marker.attr('data-lat'), $marker.attr('data-lng') );

      // create marker
      var marker = new google.maps.Marker({
        position	: latlng,
        map			: map
      });

      // add to array
      map.markers.push( marker );

      // if marker contains HTML, add it to an infoWindow
      if( $marker.html() )
      {
        // create info window
        var infowindow = new google.maps.InfoWindow({
          content		: $marker.html()
        });

        // show info window when marker is clicked
        google.maps.event.addListener(marker, 'click', function() {

          infowindow.open( map, marker );

        });
      }

    }



    function center_map( map ) {

      // vars
      var bounds = new google.maps.LatLngBounds();

      // loop through all markers and create bounds
      $.each( map.markers, function( i, marker ){

        var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );

        bounds.extend( latlng );

      });

      // only 1 marker?
      if( map.markers.length == 1 )
      {
        // set center of map
          map.setCenter( bounds.getCenter() );
          map.setZoom( 16 );
      }
      else
      {
        // fit to bounds
        map.fitBounds( bounds );
      }

    }


    $('.acf-map').each(function(){

      render_map( $(this) );

    });


    })(jQuery);

});

// Videos
function videoIframe() {
	var iframe = document.createElement('iframe');
	let embed =
		this.dataset.videoProvider == 'youtube'
			? 'https://www.youtube.com/embed/ID?autoplay=1&rel=0&showinfo=0'
			: 'https://player.vimeo.com/video/ID';
	iframe.setAttribute('src', embed.replace('ID', this.dataset.id));
	iframe.setAttribute('class', 'rwd-video-item');
	iframe.setAttribute('frameborder', '0');
	iframe.setAttribute('width', '100%');
	iframe.setAttribute('height', '100%');
	iframe.setAttribute('allowfullscreen', '1');
	this.parentNode.replaceChild(iframe, this);
}

/* Light YouTube Embeds by @labnol */
/* Web: http://labnol.org/?p=27941 */
function ytVideos() {
	const videos = document.querySelectorAll('[data-youtube-player]');
	videos.forEach(video => {
		const div = document.createElement('div');
		div.setAttribute('data-id', video.dataset.id);
		div.setAttribute('data-video-provider', 'youtube');
		div.setAttribute('class', 'rwd-video-item');
		div.innerHTML = ytThumb(video.dataset.id, video.dataset.altText);
		div.onclick = videoIframe;
		video.appendChild(div);
	});

	function ytThumb(id, alt = '') {
		var thumb =
			'<img class="rwd-video-placeholder" src="https://i.ytimg.com/vi/ID/hqdefault.jpg" role="presentation" alt="' +
			alt +
			'">';
		var play =
			'<div class="rwd-video-play"><svg class="rwd-video-play-icon" version="1.1" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 32 32"><title>Play Button</title><path d="M6 4l20 12-20 12z"></path></svg></div>';
		return thumb.replace('ID', id) + play;
	}
}

// Light Video Embeds
function vimeoVideos() {
	const videos = document.querySelectorAll('[data-vimeo-player]');
	videos.forEach((video, i) => {
		console.log(video.dataset.id);
		const id = video.dataset.id;
		const alt = video.dataset.altText;
		const div = document.createElement('div');
		div.setAttribute('data-id', id);
		div.setAttribute('data-video-provider', 'vimeo');
		div.setAttribute('class', 'rwd-video-item');
		jQuery.ajax({
			type: 'GET',
			url: 'https://vimeo.com/api/v2/video/' + id + '.json',
			jsonp: 'callback',
			dataType: 'json',
			success: function(data) {
				const thumb =
					'<img class="rwd-video-placeholder" src="' +
					data[0].thumbnail_large +
					'" role="presentation" alt="' +
					alt +
					'">';
				const play =
					'<div class="rwd-video-play"><svg class="rwd-video-play-icon" version="1.1" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 32 32"><title>Play Button</title><path d="M6 4l20 12-20 12z"></path></svg></div>';
				div.innerHTML = thumb + play;
			},
		});
		div.onclick = videoIframe;
		video.appendChild(div);
	});
}

// Init videos
document.addEventListener('DOMContentLoaded', function() {
	ytVideos();
	vimeoVideos();
});
